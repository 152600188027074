










































import { Vue, Component } from 'vue-property-decorator';
import { Textfield, Spinner } from '@components/UI';
import { Getter, Action } from 'vuex-class';
import { UpdateAvatar } from './components';
import { IUser } from '@shared/types';

@Component({
    components: { UpdateAvatar, Textfield, Spinner }
})
export default class SettingsView extends Vue {
    
    @Getter
    private currentUser: IUser;

    @Action
    private updateBio: (any) => Promise<any>

    @Action
    private enableTfa: (any) => Promise<any>

    @Action
    private disableTfa: (any) => Promise<any>

    private bio: string = '';
    private tfaCode: number | null = null;

    private loading: boolean = false;
    private qrLoading: boolean = false;
    private gotQCode: boolean = false;
    private qrCode: string = '';
    private tfaError: string = '';

    public mounted() {
        if (this.currentUser.profile)
            this.bio = this.currentUser.profile.bio || '';
    }

    public async onSubmit() {
        this.loading = true;
        await this.updateBio({ bio: this.bio });
        this.loading = false;
    }

    async onEnableTfa() {
        var code = null;
        this.tfaError = "";

        if(!this.gotQCode) {
            this.qrLoading = true;
            var resp = await this.enableTfa({code: null});
            if(resp.success) {
                this.gotQCode = true;
                this.qrCode = resp.qrCode;
                
            }
            else
                this.tfaError = resp.error;
            
            this.qrLoading = false;
        } else {
            this.qrLoading = true;
            var resp = await this.enableTfa({code: this.tfaCode});
            this.qrLoading = false;

            if(!resp.success)
                this.tfaError = resp.error;
        }
    }

    async onDisableTfa() {
        this.tfaError = '';
        this.qrLoading = true;

        var resp = await this.disableTfa({code: this.tfaCode});
        this.qrLoading = false;
        if(resp.success) {

        }
        else
            this.tfaError = resp.error;
            
    }

}

