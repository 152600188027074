














import { Vue, Component, Prop } from 'vue-property-decorator';
import { LazyImage, Spinner } from '@components/UI';
import { Action } from 'vuex-class';
import { IUser } from '@shared/types';

@Component({
    components: { LazyImage, Spinner }
})
export default class UpdateAvatar extends Vue {

    @Prop()
    public user: IUser;

    private image: File | null = null;
    private preview: any = null;
    private filename: string = '';
    private filesize: string = '';
    private loading: boolean = false;

    private active: boolean = false;

    @Action
    private updateAvatar: (any) => Promise<any>;

    private async onFilesChanged(name, files: File[]) {
        this.loading = true;
        const response = await this.updateAvatar({ image: files[0] });
        this.loading = false;
    }

    private onDragOver() {
        this.active = true;
    }

    private onDragLeave() {
        this.active = false;
    }

}

